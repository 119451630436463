import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Row, Card } from 'reactstrap';
import Notiflix from "notiflix";
import PostApiCall from './Api'
import GetApiCall from './GetApi'
import ReCAPTCHA from "react-google-recaptcha";



class StudentRegistrationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ImageApiUrl: "http://api.cniforum.com/AddImage",
            Photos: [
                {
                    image:
                        "https://thebakersnest.in/wp-content/uploads/2019/10/img_234957-600x600.png",
                    value: "",
                    data: "",
                },

            ],
            RegistrationNumber: '',
            RegistrationDate: '',
            BatchNumber: '',
            BatchStartDate: '',
            CourseOpted: 'ReactJs',
            InternshipRequired: 'No',
            FirstName: '',
            LastName: '',
            DOB: '',
            Gender: '',
            GenderData: [
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Others", label: "Others" },
            ],
            MaritalStatus: '',
            MaritalStatusData: [
                { value: "Single", label: "Single" },
                { value: "Married", label: "Married" },
                { value: "Divorsed", label: "Divorsed" },
                { value: "Widow", label: "Widow" },
                { value: "Seperated", label: "Seperated" },
            ],
            AdharCard: '',
            MobileRegex: /^[0-9]*$/,
            Mobile: '',
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            Email: '',
            FatherName: '',
            FatherNumber: '',
            InstituteName: '',
            Course: '',
            Degree: '',
            Graduation: '',
            GraduationData: [
                { value: "No formal education", label: "No formal education" },
                { value: "Primary education", label: "Primary education" },
                { value: "Secondary education", label: "Secondary education" },
                { value: "GED", label: "GED" },
                { value: "Vocational qualification", label: "Vocational qualification" },
                { value: "Bachelor's degree", label: "Bachelor's degree" },
                { value: "Master's degree", label: "Master's degree" },
                { value: "Doctorate or higher", label: "Doctorate or higher" },
            ],
            PostGraduation: '',
            CompanyName: '',
            Designation: '',
            Experience: '',
            CourseFee: '',
            AmountPaid: '',
            PaymentDate: '',
            PaymentData: [
                { value: "Cheque", label: "Cheque" },
                { value: "NEFT", label: "NEFT" },
                { value: "Cash", label: "Cash" },
            ],
            AdharCard1: '',
            TransactionID: '',
            BankName: '',
            AuthorisedSignature: '',
            StudentSignature: '',

            CountryData: [],
            CityData: [],
            StateData: [],

            CountryId: 0,
            StateId: 0,
            CityId: 0,
            Address: '',
            Country: '',
            State: '',
            City: '',
            Pincode: '',

            CountryPermanentData: [],
            CityPermanentData: [],
            StatePermanentData: [],

            CountryPermanentId: 0,
            StatePermanentId: 0,
            CityPermanentId: 0,

            PermanentCountry: '',
            PermanentState: '',
            PermanentCity: '',

            PermanenetAddress: '',
            PermanentPincode: '',
            PermanentSame: false
        }
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: '#ed1f24'
        });

        Notiflix.Notify.Init({
            position: 'left-bottom'
        });

        document.title = "Student Registration Form - React Academy | Training Institute for ReactJS & React Native in Delhi"

        GetApiCall.getRequest("GetCountry").then(resultdes =>
            resultdes.json().then(obj => {
                this.setState({
                    CountryData: obj.data,
                    CountryPermanentData: obj.data,


                })
                if (obj.data.length != 0) {
                    // console.log(obj.data[100].label)

                    this.setState({
                        CountryId: 101,
                        CountryPermanentId: 101,
                        Country: obj.data[100].value,
                        PermanentCountry: obj.data[100].value,
                    })
                }
                PostApiCall.postRequest({
                    countryid: obj.data[100].value,
                }, "GetState").then((results) =>
                    // const objs = JSON.parse(result._bodyText)
                    results.json().then(objstate => {
                        if (results.status == 200 || results.status == 201) {
                            if (objstate.data.length != 0) {


                                this.setState({
                                    StateId: objstate.data[0].value,
                                    StatePermanentId: objstate.data[0].value,
                                    StateData: objstate.data,
                                    StatePermanentData: objstate.data,
                                    PermanentState: obj.data[0].value
                                })
                            }
                            PostApiCall.postRequest({
                                stateid: objstate.data[0].value,
                            }, "GetCity").then((resultscity) =>
                                // const objs = JSON.parse(result._bodyText)
                                resultscity.json().then(objcity => {
                                    if (resultscity.status == 200 || resultscity.status == 201) {
                                        if (objcity.data.length != 0) {


                                            this.setState({
                                                CityId: objcity.data[0].value,
                                                CityPermanentId: objcity.data[0].value,
                                                CityData: objcity.data,
                                                CityPermanentData: objcity.data,
                                                PermanentCity: obj.data[0].value
                                            })
                                        }
                                    }
                                }))
                        }
                    }))

            }))
    }

    onChangeCountry(country) {

        this.setState({
            CountryId: country.target.value,
            Country: this.state.CountryData[parseInt(country.target.value) + 1].value
        })

        Notiflix.Loading.Dots('Please wait...');

        PostApiCall.postRequest(
            {
                countryid: country.target.value,
                Country: this.state.CountryData[parseInt(country.target.value) + 1].label
            },
            "GetState"
        ).then(results =>

            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        StateId: obj.data[0].value,
                        State: obj.data[0].value
                    })

                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value
                        },
                        "GetCity"
                    ).then(resultscity =>

                        resultscity.json().then(objcity => {
                            if (resultscity.status == 200 || resultscity.status == 201) {

                                this.setState({
                                    CityData: objcity.data,
                                    StateData: obj.data
                                })
                                if (objcity.data.length > 0) {
                                    // this.props.setcity(objcity.data[0].label)

                                    this.setState({

                                        CityId: objcity.data[0].value,
                                        City: objcity.data[0].value
                                    })
                                }
                                Notiflix.Loading.Remove()


                            }
                        })
                    );

                }
            })
        );
    }

    onChangeState(state) {
        //  this.props.setstate(state.target.value)
        this.setState({
            StateId: state.target.value
        })

        // Notiflix.Loading.Dots('Please wait...');
        Notiflix.Loading.Dots('Please wait...');

        for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {

            if (this.state.StateData[i].value == state.target.value) {

                // this.props.setstate();
                this.setState({
                    State: this.state.StateData[i].value
                })
            }
        }

        PostApiCall.postRequest(
            {
                stateid: state.target.value
            },
            "GetCity"
        ).then(results =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {

                    if (obj.data.length > 0) {
                        // this.props.setcity(obj.data[0].label)
                        this.setState({
                            CityData: obj.data,
                            CityId: obj.data[0].value,
                            City: obj.data[0].value
                        })
                    }
                    Notiflix.Loading.Remove()
                    // this.props.cityData(obj.data)

                }
            })
        );
    }

    onChangeCity(city) {
        // this.props.setcity(city.target.value)
        this.setState({
            CityId: city.target.value
        })

        for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {

            if (this.state.CityData[i].value == city.target.value) {
                this.setState({
                    City: this.state.CityData[i].value
                })

            }
        }
    }
    onChangePincode(pincode) {
        if ((this.state.NumRegex.test(pincode.target.value)) && (pincode.target.value.length <= 6)) {

        }
    }

    onChangePAddress(permanentaddress) {
        this.setState({
            PermanenetAddress: permanentaddress.target.value
        })
    }
    onChangePCountry(country) {
        this.setState({
            CountryPermanentId: country.target.value,
            PermanentCountry: this.state.CountryPermanentData[parseInt(country.target.value - 1)].value
        })


        Notiflix.Loading.Dots('Please wait...');

        PostApiCall.postRequest(
            {
                countryid: country.target.value
            },
            "GetState"
        ).then(results =>

            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        StatePermanentId: obj.data[0].value
                    })

                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value
                        },
                        "GetCity"
                    ).then(resultscity =>

                        resultscity.json().then(objcity => {
                            if (resultscity.status == 200 || resultscity.status == 201) {

                                this.setState({
                                    CityPermanentData: objcity.data,
                                    StatePermanentData: obj.data
                                })
                                if (objcity.data.length > 0) {


                                    this.setState({

                                        CityPermanentId: objcity.data[0].value
                                    })
                                }
                                Notiflix.Loading.Remove()


                            }
                        })
                    );

                }
            })
        );


    }

    onChangePState(state) {
        this.setState({
            StatePermanentId: state.target.value,

        })

        Notiflix.Loading.Dots('Please wait...');

        for (var i = 0; i < Object.keys(this.state.StatePermanentData).length; i++) {

            if (this.state.StatePermanentData[i].value == state.target.value) {

                this.setState({
                    PermanentState: this.state.StatePermanentData[i].value
                })
            }
        }

        PostApiCall.postRequest(
            {
                stateid: state.target.value
            },
            "GetCity"
        ).then(results =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then(obj => {
                if (results.status == 200 || results.status == 201) {

                    if (obj.data.length > 0) {

                        this.setState({
                            CityPermanentData: obj.data,
                            CityPermanentId: obj.data[0].value
                        })
                    }
                    Notiflix.Loading.Remove()
                    // this.props.cityData(obj.data)

                }
            })
        );
    }

    onChangePCity(city) {
        this.setState({
            CityPermanentId: city.target.value,
            // PermanentCity: this.state.CityPermanentData[i].value
        })

        for (var i = 0; i < Object.keys(this.state.CityPermanentData).length; i++) {

            if (this.state.CityPermanentData[i].value == city.target.value) {

                this.setState({
                    PermanentCity: this.state.CityPermanentData[i].value
                })

            }
        }
    }

    onSubmit() {

        if (this.state.RegistrationNumber != '') {
            if (this.state.RegistrationDate != '') {
                if (this.state.BatchNumber != '') {
                    if (this.state.BatchStartDate != '') {
                        if (this.state.FirstName != '') {
                            if (this.state.LastName != '') {
                                if (this.state.DOB != '') {
                                    if (this.state.Gender != '') {
                                        if (this.state.MaritalStatus != '') {
                                            if (this.state.AdharCard != '') {
                                                if (this.state.AdharCard.length == 12) {
                                                    if (this.state.Mobile != '') {
                                                        if (this.state.Mobile.length == 10) {
                                                            if (this.state.Email != '') {
                                                                if (this.state.EmailRegex.test(this.state.Email)) {
                                                                    if (this.state.FatherName != '') {
                                                                        if (this.state.FatherNumber != '') {
                                                                            if (this.state.FatherNumber.length == 10) {
                                                                                if (this.state.Address != '') {
                                                                                    if (this.state.Country != '') {
                                                                                        if (this.state.State != '') {
                                                                                            if (this.state.City != '') {
                                                                                                if (this.state.Pincode != '') {
                                                                                                    if (this.state.PermanenetAddress != '') {
                                                                                                        if (this.state.PermanentCountry != '') {
                                                                                                            if (this.state.StatePermanent != '') {
                                                                                                                if (this.state.PermanentCity != '') {
                                                                                                                    if (this.state.PermanentPincode != '') {
                                                                                                                        if (this.state.InstituteName != '') {
                                                                                                                            if (this.state.Course != '') {
                                                                                                                                if (this.state.Degree != '') {
                                                                                                                                    if (this.state.Graduation != '') {
                                                                                                                                        if (this.state.CourseFee != '') {
                                                                                                                                            if (this.state.AmountPaid != '') {
                                                                                                                                                if (this.state.PaymentDate != '') {
                                                                                                                                                    if (this.state.PaymentMode != '') {
                                                                                                                                                        if (this.state.AdharCard1 != '') {
                                                                                                                                                            if (this.state.AdharCard1.length == 12) {
                                                                                                                                                                if (this.state.TransactionID != '') {
                                                                                                                                                                    if (this.state.BankName != '') {
                                                                                                                                                                        if (this.state.AuthorisedSignature != '') {
                                                                                                                                                                            if (this.state.StudentSignature != '') {
                                                                                                                                                                                this.onPost()
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                Notiflix.Notify.Failure('Please enter your Signature.');
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            Notiflix.Notify.Failure('Please enter Authorised Signature.');
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        Notiflix.Notify.Failure('Please enter Bank Name.');
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    Notiflix.Notify.Failure('Please enter your Transaction Id.');
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                Notiflix.Notify.Failure('Please enter valid Adhar Card Number.');
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            Notiflix.Notify.Failure('Please enter Adhar Card Number.');
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        Notiflix.Notify.Failure('Please select Payment Mode.');
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    Notiflix.Notify.Failure('Please select Payment Date.');
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                Notiflix.Notify.Failure('Please enter Amount Paid in Digits.');
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            Notiflix.Notify.Failure('Please enter Course Fee in Digits.');
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        Notiflix.Notify.Failure('Please select Graduation.');
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    Notiflix.Notify.Failure('Please enter your degree.');
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                Notiflix.Notify.Failure('Please enter Course.');
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            Notiflix.Notify.Failure('Please enter your Institute Name.');
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        Notiflix.Notify.Failure('Please enter Permanent pincode');
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    Notiflix.Notify.Failure('Please select Permanent City');
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                Notiflix.Notify.Failure('Please select Permanent State');
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            Notiflix.Notify.Failure('Please select Permanent Country');
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        Notiflix.Notify.Failure('Please enter Permanent Address');
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    Notiflix.Notify.Failure('Please enter pincode');
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                Notiflix.Notify.Failure('Please select City');
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            Notiflix.Notify.Failure('Please select State');
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        Notiflix.Notify.Failure('Please select Country');
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    Notiflix.Notify.Failure('Please enter Address');
                                                                                }
                                                                            }
                                                                            else {
                                                                                Notiflix.Notify.Failure("Please enter valid Spouse/Father's Number.");
                                                                            }
                                                                        }
                                                                        else {
                                                                            Notiflix.Notify.Failure("Please enter your Spouse/Father's Number.");
                                                                        }
                                                                    }
                                                                    else {
                                                                        Notiflix.Notify.Failure("Please enter your Spouse/Father's Name.");
                                                                    }
                                                                }
                                                                else {
                                                                    Notiflix.Notify.Failure("Please enter valid Email Address.");
                                                                }
                                                            }
                                                            else {
                                                                Notiflix.Notify.Failure("Please enter your Email Address.");
                                                            }
                                                        }
                                                        else {
                                                            Notiflix.Notify.Failure("Please enter valid Mobile Number.");
                                                        }
                                                    }
                                                    else {
                                                        Notiflix.Notify.Failure("Please enter your Mobile Number.");
                                                    }
                                                }
                                                else {
                                                    Notiflix.Notify.Failure("Please enter valid Adhar Card Number.");
                                                }
                                            }
                                            else {
                                                Notiflix.Notify.Failure("Please enter your Adhar Card Number.");
                                            }
                                        }
                                        else {
                                            Notiflix.Notify.Failure("Please select your Marital Status.");
                                        }
                                    }
                                    else {
                                        Notiflix.Notify.Failure("Please select your Gender.");
                                    }
                                }
                                else {
                                    Notiflix.Notify.Failure("Please select your Date of Birth.");
                                }
                            }
                            else {
                                Notiflix.Notify.Failure("Please enter your Last Name.");
                            }
                        }
                        else {
                            Notiflix.Notify.Failure("Please enter your First Name.");
                        }
                    } else {
                        Notiflix.Notify.Failure('Please enter Batch Start Date');
                    }
                } else {
                    Notiflix.Notify.Failure('Please select Batch Number.');
                }
            } else {
                Notiflix.Notify.Failure('Please enter Registration Date.');
            }
        } else {
            Notiflix.Notify.Failure('Please enter your Registration Number.');
        }
    }

    onPost = () => {
        // console.log('api')
        Notiflix.Loading.Dots('Please wait...');
        // var login=localStorage.getItem('LoginDetail');
        // var details=JSON.parse(login) 


        PostApiCall.postRequest({
            // id: 0,
            registrationno: this.state.RegistrationNumber,
            registrationdate: this.state.RegistrationDate,
            batchnumber: this.state.BatchNumber,
            batchstartdate: this.state.BatchStartDate,
            internshiprequired: this.state.InternshipRequired,
            photos: '',
            firstname: this.state.FirstName,
            lastname: this.state.LastName,
            dob: this.state.DOB,
            gender: this.state.Gender,
            maritalstatus: this.state.MaritalStatus,
            adhar: this.state.AdharCard,
            mobile: this.state.Mobile,
            email: this.state.Email,
            fathername: this.state.FatherName,
            fathernumber: this.state.FatherNumber,
            presaddress: this.state.Address,
            prescountry: this.state.Country,
            presstate: this.state.State,
            prescity: this.state.City,
            prespincode: this.state.Pincode,
            peraddress: this.state.PermanenetAddress,
            percountry: this.state.PermanentCountry,
            perstate: this.state.PermanentState,
            percity: this.state.PermanentCity,
            perpincode: this.state.PermanentPincode,
            institutename: this.state.InstituteName,
            course: this.state.Course,
            degree: this.state.Degree,
            graduation: this.state.Graduation,
            postgraduation: this.state.PostGraduation != '' ? this.state.PostGraduation : null,
            companyname: this.state.CompanyName != '' ? this.state.CompanyName : null,
            designation: this.state.Designation != '' ? this.state.Designation : null,
            experience: this.state.Experience != '' ? this.state.Experience : null,
            coursefee: this.state.CourseFee,
            amountpaid: this.state.AmountPaid,
            paymentdate: this.state.PaymentDate,
            paymentmode: this.state.PaymentMode,
            adharcard: this.state.AdharCard1,
            transactonid: this.state.TransactionID,
            bankname: this.state.BankName,
            authorisedsignature: this.state.AuthorisedSignature,
            studentsignature: this.state.StudentSignature,
            // userid: ,
            action: 'INSERT',
        }, "Add_Student_Registration").then((resultcategory =>
            resultcategory.json().then(obj => {
                // console.log(obj)

                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Loading.Remove();
                    // Notiflix.Notify.Success('Registration successfully Completed.')
                    //    this.onimage(obj)
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data)
                }
            })))



    }

    render() {
        return (
            <React.Fragment>
                <div className="app">
                    <div className="page-content-registration">
                        <Row>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div className="react-academy-logo">
                                    <img src="logos/logos/REACT ACADEMY.png" style={{ width: '30%' }} alt="React Academy Logo" />
                                    <p style={{ fontWeight: ' 500' }}>A Subsidiary of Global Trendz.</p>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div className="registration-address">
                                    <h5 style={{ fontWeight: ' 700' }}>Global Trendz</h5>
                                    <text>D 200, 3rd Floor, Mansarover Garden</text><br />
                                    <text>New Delhi - 110 015. INDIA.</text><br />
                                    <text>+91 7827 795 895, +91 11 4703 7722</text><br />
                                    <text>reactacademy@globaltrendz.com</text><br />
                                    <text>www.reactacademy.com</text>
                                </div>
                            </div>
                        </Row>
                        <form>
                            <div class="card d-flex justify-content-center align-items-center registration-heading">
                                <h3 class="registration-heading-text"> STUDENT REGISTRATION FORM</h3>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                        <form>
                                            <div class="col-12">
                                                <Row>
                                                    <div class="col-lg-12 col-md-12">
                                                        <h5 class="registration-form-heading">REGISTRATION DETAILS</h5>
                                                    </div>
                                                    <Row>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                                <label class="registration-form-label">Registration Number : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <input class="form-control" type="text" name="name"
                                                                        value={this.state.RegistrationNumber}
                                                                        onChange={(name) => {
                                                                            this.setState({
                                                                                RegistrationNumber: name.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="col-lg-12 col-md-12">
                                                                <label class="registration-form-label">Registration Date : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <input class="registration-form-input" type="date"
                                                                        value={this.state.RegistrationDate}
                                                                        onChange={(name) => {
                                                                            this.setState({
                                                                                RegistrationDate: name.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="col-lg-12 col-md-12">
                                                                <label class="registration-form-label">Batch Number : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <input class="form-control" type="text" name="name"
                                                                        value={this.state.BatchNumber}
                                                                        onChange={(name) => {
                                                                            this.setState({
                                                                                BatchNumber: name.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="col-lg-12 col-md-12">
                                                                <label class="registration-form-label">Batch Start Date : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <input class="registration-form-input" type="date" name="name"
                                                                        value={this.state.BatchStartDate}
                                                                        onChange={(name) => {
                                                                            this.setState({
                                                                                BatchStartDate: name.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row style={{ marginLeft: '1%' }}>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <h5 class="registration-form-heading" >INTERNSHIP REQUIRED<span className="mandatory">*</span></h5>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="optradio"
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={this.state.InternshipRequired == 'Yes' ? true : false}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            InternshipRequired: 'Yes'
                                                                        })
                                                                    }}
                                                                /> Yes
                                                            </label>
                                                            <label class="radio-inline" style={{ marginLeft: '10px' }}>
                                                                <input type="radio" name="optradio"
                                                                    checked={this.state.InternshipRequired == 'No' ? true : false}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            InternshipRequired: 'No'
                                                                        })
                                                                    }} /> No
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12    ">
                                                            <h5 class="registration-form-heading">COURSE OPTED FOR</h5>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="optradio"
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={this.state.CourseOpted == 'ReactJs' ? true : false}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            CourseOpted: 'ReactJs'
                                                                        })
                                                                    }} /> ReactJs
                                                            </label>
                                                            <label class="radio-inline" style={{ marginLeft: '10px' }}>
                                                                <input type="radio" name="optradio"
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={this.state.CourseOpted == 'ReactNative' ? true : false}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            CourseOpted: 'ReactNative'
                                                                        })
                                                                    }} /> ReactNative
                                                            </label>
                                                        </div>
                                                    </Row>
                                                </Row>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        <label for="sw-arrows-first-name" style={{ marginTop: "3%" }}>
                                            Upload Image
                                            <span className="mandatory">*</span>
                                        </label>
                                        <div class="div1" class="row d-flex justify-content-center alignitems-center">
                                            {this.state.Photos.map((photo, index) => (
                                                <div class="img-div"
                                                    style={{ marginLeft: "1%", marginRight: "2%" }}
                                                >
                                                    <label className="custom-file-upload fas">
                                                        <div
                                                            className="img-wrap img-upload"
                                                            style={{ width: "75%", height: "auto" }}
                                                        >
                                                            <img
                                                                for="photo-uploads"
                                                                src={photo.image}
                                                                style={{
                                                                    width: "80%",
                                                                    height: "auto",
                                                                    borderRadius: "5%",
                                                                }}
                                                            />
                                                        </div>
                                                        <input
                                                            class="img-choosefile col-lg-12 col-md-12"
                                                            id="photo-uploads"
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={(e) => {
                                                                if (e.target.files[0] != undefined) {
                                                                    if (e.target.files[0].size < 400000) {
                                                                        var arr = [...this.state.Photos];
                                                                        const reader = new FileReader();
                                                                        const file = e.target.files[0];
                                                                        reader.onload = (e) => {
                                                                            arr[index].image = e.target.result;
                                                                            arr[index].value = e.target.result;
                                                                            arr[index].data = file;
                                                                            // this.props.setstaffimage(e.target.result);
                                                                            this.setState({
                                                                                Photos: arr,
                                                                                imageCount:
                                                                                    this.state.imageCount + 1,
                                                                            });
                                                                        };
                                                                        reader.readAsDataURL(file);
                                                                    } else {
                                                                        Notiflix.Notify.Failure(
                                                                            "File too large, upload file less than 400 kb."
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12">
                                    <div class="col-lg-12 col-md-12">
                                        <form>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <h5 class="registration-form-heading">PERSONAL DETAILS</h5>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">First Name : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.FirstName}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            FirstName: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Last Name : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.LastName}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            LastName: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Date of Birth : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="registration-form-input" type="date"
                                                                    value={this.state.DOB}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            DOB: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Gender : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span" style={{ marginTop: '2%' }}>
                                                                <select class="registration-form-input"
                                                                    value={this.state.Gender}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            Gender: name.target.value
                                                                        })
                                                                    }}
                                                                >
                                                                    <option value=''>Select</option>
                                                                    {this.state.GenderData.map(status => (
                                                                        <option key={status.value} value={status.value}>
                                                                            {status.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="col-lg-12 col-md-12 " style={{ marginLeft: '3%' }}>
                                                                <label class="registration-form-label" >Marital Status : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <select class="registration-form-input"
                                                                        value={this.state.MaritalStatus}
                                                                        onChange={(name) => {
                                                                            this.setState({
                                                                                MaritalStatus: name.target.value
                                                                            })
                                                                        }}
                                                                    >
                                                                        <option value=''>Select</option>
                                                                        {this.state.MaritalStatusData.map(status => (
                                                                            <option key={status.value} value={status.value}>
                                                                                {status.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="col-lg-12 col-md-12">
                                                                <label class="registration-form-label">Adhar Card : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <input class="form-control" type="text" name="name"
                                                                        value={this.state.AdharCard}
                                                                        onChange={(adhar) => {
                                                                            if ((this.state.MobileRegex.test(adhar.target.value)) && (adhar.target.value.length <= 12)) {
                                                                                this.setState({
                                                                                    AdharCard: adhar.target.value
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Mobile Number : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.Mobile}
                                                                    onChange={(mobile) => {
                                                                        if ((this.state.MobileRegex.test(mobile.target.value)) && (mobile.target.value.length <= 10)) {
                                                                            this.setState({
                                                                                Mobile: mobile.target.value
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Email Address : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.Email}
                                                                    onChange={(email) => {
                                                                        this.setState({
                                                                            Email: email.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Father/Spouse Name : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.FatherName}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            FatherName: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Father/Spouse Number : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.FatherNumber}
                                                                    onChange={(mobile) => {
                                                                        if ((this.state.MobileRegex.test(mobile.target.value)) && (mobile.target.value.length <= 10)) {
                                                                            this.setState({
                                                                                FatherNumber: mobile.target.value
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12 ">
                                    <div class="col-lg-12 col-md-12">
                                        <form>
                                            <div class="col-12">
                                                <div class="row">

                                                    {/*=========================== Present Address================================ */}
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <h5 class="registration-form-heading">PRESENT ADDRESS</h5>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Address : <span className="mandatory">*</span></label>
                                                            <textarea class="registration-form-input" type="text" name="name"
                                                                value={this.state.Address}
                                                                onChange={(name) => {
                                                                    this.setState({
                                                                        Address: name.target.value
                                                                    })
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">Country<span className="mandatory">*</span></label>
                                                            <select type="text" className="registration-form-input" value={this.state.CountryId}
                                                                onChange={this.onChangeCountry.bind(this)}>
                                                                {this.state.CountryData.map(
                                                                    schedule => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">State<span className="mandatory">*</span></label>
                                                            <select type="text" className="registration-form-input"
                                                                value={this.state.StateId}
                                                                onChange={this.onChangeState.bind(this)}>
                                                                {this.state.StateData.map(
                                                                    schedule => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">City<span className="mandatory">*</span></label>
                                                            <select type="text" className="registration-form-input"
                                                                value={this.state.CityId}
                                                                onChange={this.onChangeCity.bind(this)}>
                                                                {this.state.CityData.map(
                                                                    schedule => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">Pincode<span className="mandatory">*</span></label>
                                                            <input type="text" class="form-control"
                                                                value={this.state.Pincode}
                                                                onChange={(pincode) => {
                                                                    if ((this.state.NumRegex.test(pincode.target.value)) && (pincode.target.value.length <= 6)) {
                                                                        this.setState({
                                                                            Pincode: pincode.target.value
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*=========================== Present Address End================================ */}
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <input class="registration-form-checkbox" type="checkbox"
                                                            style={{ marginLeft: '2%' }}
                                                            checked={this.state.PermanentSame}
                                                            onChange={() => {
                                                                this.setState({ PermanentSame: !this.state.PermanentSame }, () => {
                                                                    if (this.state.PermanentSame) {
                                                                        this.setState({
                                                                            PermanenetAddress: this.state.Address,
                                                                            PermanentCountry: this.state.Country,
                                                                            PermanentState: this.state.State,
                                                                            PermanentCity: this.state.City,
                                                                            CountryPermanentId: this.state.CountryId,
                                                                            StatePermanentId: this.state.StateId,
                                                                            CityPermanentId: this.state.CityId,
                                                                            PermanentPincode: this.state.Pincode1
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            PermanentCountry: '',
                                                                            PermanentState: '',
                                                                            PermanentCity: '',
                                                                            PermanenetAddress: '',
                                                                            CountryPermanentId: 0,
                                                                            StatePermanentId: 0,
                                                                            CityPermanentId: 0,
                                                                            PermanentPincode: ''
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        <label class="registration-form-checkbox-label" style={{ marginLeft: '2%' }}>Permanent Address is same as  Present Address</label>
                                                    </div>
                                                    {/*=========================== Permanent Address================================ */}

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ display: this.state.PermanentSame ? 'none' : '' }}>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <h5 class="registration-form-heading">PERMANENT ADDRESS</h5>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Address : <span className="mandatory">*</span></label>
                                                            <textarea class="registration-form-input" type="text"
                                                                value={this.state.PermanenetAddress}
                                                                onChange={this.onChangePAddress.bind(this)} >
                                                            </textarea>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">Country<span className="mandatory">*</span></label>
                                                            <select type="text" className="registration-form-input"
                                                                value={this.state.CountryPermanentId}
                                                                onChange={this.onChangePCountry.bind(this)} >
                                                                {this.state.CountryPermanentData.map(
                                                                    schedule => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">State<span className="mandatory">*</span></label>
                                                            <select type="text" className="registration-form-input"
                                                                value={this.state.StatePermanentId}
                                                                onChange={this.onChangePState.bind(this)} >
                                                                {this.state.StatePermanentData.map(
                                                                    schedule => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">City<span className="mandatory">*</span></label>
                                                            <select type="text" className="registration-form-input"
                                                                value={this.state.CityPermanentId}
                                                                disabled={this.state.Editable}
                                                                onChange={this.onChangePCity.bind(this)} >
                                                                {this.state.CityPermanentData.map(
                                                                    schedule => (
                                                                        <option
                                                                            key={schedule.label}
                                                                            value={schedule.value}
                                                                        >
                                                                            {schedule.label}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                            <label for="validationCustom05">Pincode<span className="mandatory">*</span></label>
                                                            <input type="text" class="form-control"
                                                                value={this.state.PermanentPincode}
                                                                onChange={(pincode) => {
                                                                    if ((this.state.NumRegex.test(pincode.target.value)) && (pincode.target.value.length <= 6)) {
                                                                        this.setState({
                                                                            PermanentPincode: pincode.target.value
                                                                        })
                                                                    }
                                                                }} />
                                                        </div>
                                                    </div>
                                                    {/*=========================== Permanent Address End================================ */}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12 ">
                                    <div class="col-lg-12 col-md-12">
                                        <form>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <h5 class="registration-form-heading">FOR STUDENT</h5>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Institute Name : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.InstituteName}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            InstituteName: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Course : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.Course}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            Course: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-xs-12">
                                                            <label class="registration-form-label">Degree : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.Degree}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            Degree: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-xs-12">
                                                            <label class="registration-form-label">Graduation : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <select class="registration-form-input" type="text" name="name">
                                                                    <option value=''>Select</option>
                                                                    {this.state.GraduationData.map(status => (
                                                                        <option key={status.value} value={status.value}>
                                                                            {status.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12">
                                                            <label class="registration-form-label">Post Graduation</label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.PostGraduation}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            PostGraduation: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12 ">
                                    <div class="col-lg-12 col-md-12">
                                        <form>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <h5 class="registration-form-heading">FOR PROFESSIONAL</h5>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-xs-12">
                                                            <label class="registration-form-label">Company Name :</label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.CompanyName}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            CompanyName: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-xs-12">
                                                            <label class="registration-form-label">Designation :</label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.Designation}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            Designation: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-xs-12">
                                                            <label class="registration-form-label">Experience :</label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.Experience}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            Experience: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12 ">
                                    <div class="col-lg-12 col-md-12">
                                        <form>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                        <h5 class="registration-form-heading">FOR OFFICE USE ONLY</h5>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Course Fee : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.CourseFee}
                                                                    onChange={(mobile) => {
                                                                        if ((this.state.MobileRegex.test(mobile.target.value)) && (mobile.target.value.length <= 6)) {
                                                                            this.setState({
                                                                                CourseFee: mobile.target.value
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Amount Paid : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.AmountPaid}
                                                                    onChange={(mobile) => {
                                                                        if ((this.state.MobileRegex.test(mobile.target.value)) && (mobile.target.value.length <= 6)) {
                                                                            this.setState({
                                                                                AmountPaid: mobile.target.value
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Payment Date : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="registration-form-input" type="date" name="name"
                                                                    value={this.state.PaymentDate}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            PaymentDate: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ marginLeft: '2%' }}>
                                                                <label class="registration-form-label">Payment Mode : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <select class="registration-form-input" type="date"
                                                                        value={this.state.PaymentMode}
                                                                        onChange={(name) => {
                                                                            this.setState({
                                                                                PaymentMode: name.target.value
                                                                            })
                                                                        }}
                                                                    >
                                                                        <option value=''>Select</option>
                                                                        {this.state.PaymentData.map(payment => (
                                                                            <option key={payment.value} value={payment.value}>
                                                                                {payment.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-md-12">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ margonRight: '2%' }}>
                                                                <label class="registration-form-label">Adhar Card : <span className="mandatory">*</span></label>
                                                                <span class="registration-form-span">
                                                                    <input class="form-control" type="text" name="name"
                                                                        value={this.state.AdharCard1}
                                                                        onChange={(adhar) => {
                                                                            if ((this.state.MobileRegex.test(adhar.target.value)) && (adhar.target.value.length <= 12)) {
                                                                                this.setState({
                                                                                    AdharCard1: adhar.target.value
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Transaction ID : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.TransactionID}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            TransactionID: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Bank Name : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.BankName}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            BankName: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card d-flex justify-content-center align-items-center">
                                <div class="card-body registration-card col-lg-12 col-md-12 col-sm-12" style={{ paddingTop: '1%' }}>
                                    <div class="col-lg-12 col-md-12">
                                        <form>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Authorised Signatory : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.AuthorisedSignature}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            AuthorisedSignature: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-md-12">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label class="registration-form-label">Signature (Student) : <span className="mandatory">*</span></label>
                                                            <span class="registration-form-span">
                                                                <input class="form-control" type="text" name="name"
                                                                    value={this.state.StudentSignature}
                                                                    onChange={(name) => {
                                                                        this.setState({
                                                                            StudentSignature: name.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style={{ marginBottom: '2%' }}>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button class="btn registration-form-button" onClick={this.onSubmit.bind(this)}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default StudentRegistrationForm;